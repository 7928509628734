@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-link {
    @apply flex lg:inline text-violet py-5 px-5 lg:p-0 mx-7 font-semibold hover:text-blue transition-all;
  }
  th,
  td {
    @apply p-0;
  }
}

@layer utilities {
  .nav-link:hover {
    text-shadow: 0px 0px 4px #00ebff;
  }
  svg:hover {
    filter: drop-shadow(0 0 3px #00ebff);
  }

  .svg-pink:hover {
    filter: drop-shadow(0 0 3px #ff39b0);
  }

  .text-outline-pink {
    -webkit-text-stroke: 1px #ff39b0;
    text-stroke: 1px #ff39b0;
  }
  .text-outline-blue {
    -webkit-text-stroke: 1px #00ffff;
    text-stroke: 1px #00ffff;
  }
}
