html {
  background-color: #0e0e1a;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background: linear-gradient(180deg, #201740 0%, rgba(32, 23, 64, 0) 119.59%);

  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes blinkPink {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

@keyframes blinkBlue {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

.blink-pink {
  animation: blinkPink linear infinite 3s;
}

.blink-blue {
  animation: blinkBlue linear infinite 4s;
}

@keyframes blinkingIcon {
  0% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }

  33% {
    fill: transparent;
  }

  49% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }
  60% {
    fill: transparent;
  }
  99% {
    fill: transparent;
  }
  100% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }
}
.blinking {
  animation: blinkingIcon 1.2s infinite;
}

@keyframes blinkingIconGold {
  0% {
    filter: drop-shadow(0 0 10px #f8c402);
  }

  33% {
    filter: none;
  }

  49% {
    filter: drop-shadow(0 0 10px #f8c402);
  }
  60% {
    filter: none;
  }
  99% {
    filter: none;
  }
  100% {
    filter: drop-shadow(0 0 10px #f8c402);
  }
}
.blinking-gold {
  animation: blinkingIconGold 1.2s infinite;
}

@keyframes blinking-text-teal {
  0% {
    color: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }

  50% {
    color: transparent;
  }

  100% {
    color: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }
}

.blink-text-teal {
  animation: blinking-text-teal linear 2s infinite;
}
